.cart-addon-container-desktop {
  .cart-indulgent-global {
    .modal-content {
      height: auto;
      max-height: 88%;
      top:3%;
      @media #{$breakpoint-ipad-12-pro-portrait}{
        @media(orientation: portrait){
          top:1%;
          bottom: 55%;
          width: 80%;
          left:11%;
          height: 32% !important;
        }
      }
      @media #{$breakpoint-ipad-pro-12-landscape}{
        @media(orientation: landscape){
          top:1%;
          bottom: 49%;
          left:16%;
          height: fit-content;
        }
      }
      @media #{$breakpoint-desktop-small}{
          width: 70% !important;
         }

      .modal-header .header-title {
        font-weight: bold;
        font-size: 24px;
        margin: 0;
        padding-left: 3%;
        @media #{$breakpoint-below-tablet}{
          font-family: $National2Regular;
        }
        @media #{$breakpoint-samsung-galaxy-tab-s7}{
          font-family: $National2Regular;
        }
        @media #{$mobile-only}{
          font-weight: 500;
          letter-spacing: normal;
        }
      }

      .custom-modal-close {
        right: 2.5% !important;
        position: static;
        padding-right: 2%;
      }

      .modal-body {
        padding-bottom: 0 !important;
      }
    }
    .indulgent-container-global {
      display: flex;
      flex-direction: column;

      .indulgent-card-list-global {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        padding-left: 4%;
        padding-top: 20px;
        margin-bottom: 10px;
        @media #{$breakpoint-samsung-galaxy-tab-s7}{
          padding-left: 31%;
          min-height: 80vh;
          display: inline;
        }

        .indulgent-card-global {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          min-height: 70px;
          width: 29%;
          height: 210px !important;
          margin-right: 20px;
          position: relative;
          border-radius: 7px;
          margin-bottom: 20px;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

          @media #{$breakpoint-ipad-pro-tablet}{
            width: 29%;
          }

          .indulgentaddOns-card-image-global {
            border-radius: 7px;
            // background-blend-mode: multiply;
            // background: linear-gradient(190.77deg, rgba(32, 33, 36, 0) 21.45%, #202124 98.01%);
            width: 100%;
            transition:all .7s;
            -webkit-transition:all .7s;
            @include imageAnimation;
            // height: 213px;
            @media #{$breakpoint-above-tablet}{
              &:hover{
                -ms-transform:scale(1.1);
                transform:scale(1.1);
              }
            }
            @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
              height: 100%;
            }
            @media #{$breakpoint-ipad-pro-tablet}{
              width: 100%;
            }
          }

          .indulgentaddOns-card-content-global {
            position: absolute;
            left: 7.14%;
            right: 10.71%;
            bottom: 11px;

            .indulgent-card-header-global {
              font-family: $National2Condensed;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 25px;
              display: flex;
              align-items: flex-end;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: $white;
            }

            .indulgent-card-price-global {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              display: flex;
              align-items: flex-end;
              text-align: right;
              color: $white;
              padding-bottom: 6px;
            }

            .indulgent-card-button {
              white-space: nowrap;
              @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
                padding: 0 20px;
              }
            }
          }
        }
      }
    }
  }
}

.cart-indulgent-global {
  .modal-body {
    background: $white !important;
    padding: 0 !important;
  }
  .ContinueButton {
    border-top: 1px solid #dbdbdb;
    position: sticky;
    bottom: -1px;
    background: $white;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding-top: 23px;
    padding-bottom: 23px;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      bottom: 0;
    }
     @media #{$breakpoint-samsung-galaxy-tab-s7} {
       margin-top: 18%;
      }
      .indulgent-card-button {
        @media #{$mobile-only}{
          width: 208px !important;
          height: 44px !important;
          padding-left: 40px !important;
          padding-right: 40px !important;
        }
      }
  }
  .modal-content {
     @media #{$breakpoint-samsung-galaxy-tab-s7} {
      height: 100%;
     }
  }
}
.add-ons-toast {
  width: 298px;
  top: 451px;
  padding: 0px !important;

  & .addons-text-div {
    text-align: $center;
  }

  & .text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    font-family: $National2Regular;
    margin-left: 5px;
    @media #{$breakpoint-below-iphoneX} {
      font-size: 10px;
    }
  }
}
.Toastify__toast-container-ItemIndulge {
	bottom: 20% !important;
  text-align: center;
  
	@media #{$breakpoint-below-tablet} {
	  top: 73% !important;
	  width: 90%;
	  left: 4%;
	  padding: 11px;
	  text-align: center;
	}
}

.cart-addon-container-mobile {
  .modal-header {
    .header-title {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      text-transform: capitalize;
      color: #494949;
    }
    .custom-modal-close {
      right: 3% !important;
      top: auto !important;
      }
  }
  .indulgent-container-global {
    .indulgent-card-list-global {
      min-height: 80vh;
      @media (max-width: 413px) {
        padding: 30px 10% 10px 10%;
      }

      @media #{$breakpoint-iphone-six-plus} {
        padding: 30px 39px 10px 37px;
      }
      @media #{$breakpoint-tablet-only} {
        padding: 30px 39px 10px 37px;
      }

      .indulgent-card-global {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 70px;
        height: 210px !important;
        position: relative;
        border-radius: 7px;
        margin-bottom: 20px;
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
        @media #{$breakpoint-samsung-galaxy-tab-s7}{
          width:44.5%;
          margin-left: 27.7%;
        }
        .indulgentaddOns-card-image-global {
          // height: 225px;
          width: 100%;
          border-radius: 7px;
          // background: linear-gradient(190.77deg, rgba(32, 33, 36, 0) 21.45%, #202124 98.01%);
        }

        .indulgentaddOns-card-content-global {
          position: absolute;
          left: 6.67%;
          right: 16.67%;
          bottom: 11%;

          .indulgent-card-header-global {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: flex-end;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $white;
          }

          .indulgent-card-price-global {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: flex-end;
            text-align: right;
            color: $white;
            padding-bottom: 5px;
          }
          .button-contained {
            @media #{$mobile-only}{
              padding-left: 37px !important;
              padding-right: 37px !important;
              width: 157px !important;
            }
          }
        }
      }
    }
  }
}

@media #{$breakpoint-below-tablet} {
  .pdp-addon-modal{
    .modal-content{ 
      height: 100%!important;
      max-height: 100%!important;
      top:0%!important;
    }
    .indulgent-card-global{
      width: 300px!important;      
    }
    .indulgent-card-list-global{
      justify-content: center;
    }
    .header-title{
      font-size: 14px!important;
      text-transform: none;
      left: 0% !important;
      margin: auto !important;
    }
    .indulgentaddOns-card-image-global{
      // height: 225px;
    }
  }
}

@media #{$mobile-only} {
  .pdp-addon-modal{
    .indulgent-card-list-global{
      position: relative;
        padding-bottom: 18%;
    }
    .ContinueButton {
      position: fixed !important;
    }
  }
}

.pdp-indulgent-toast{
  width: 230px;
  margin:auto;
}
.pdp-indulgent-toast .text{
  margin-left: 5px;
}