.proceedpayment-container {
  .modal {
    display: block;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $z-index-max + 1;

    .modal-dialog {
      width: 580px;
      margin-top: 37px;
      margin-bottom: 37px;

      @media screen and (max-width: 575px) {
        width: 100%;
        margin-top: 8px;
        margin-left: 0;
      }

      .modal-header {
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #202124;

        .class {
          width: 52px;
          height: 34px;
        }
      }

      .modal-header-parent {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgb(222, 226, 230);
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        padding: 1rem;
        align-items: flex-start;

        h2 {
          padding: 5px 0 0 7px;
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #202124;

          .class {
            width: 52px;
            height: 34px;
          }
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        padding: 30px 20px 40px 20px;
        background: #f8f7f5;
      }

      .modal-footer {
        padding: 18px 0 28px 0;
        margin-left: 140px;
        margin-right: 140px;
        border-top-width: 0;

        button {
          width: 302px;
          height: 46px;
          padding: 0;
          border-radius: 200px;
          margin: 0;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          align-items: center;
          text-align: center;
          color: #fff;
          box-shadow: none;
        }

        .btn-dark:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #202124;
          border-radius: 200px;
        }

        button.btn:active:focus {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #202124;
          border-radius: 200px;
          box-shadow: none;
        }
      }
    }
  }
}

.payment-form-credit-card-container {
  .card-number-image {
    position: relative;
    float: right;
    top: -43px;
    right: 10px;
  }

  .payment-mandatory-note {
    font-family: $National2Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    color: #494949;
    margin: 0px;
    text-align: right;
  }
}

.tender-type-label {
  cursor: pointer !important;

  input {
    margin-right: 10px;
  }
}

.payment-option-container {
  .payment-option {
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin: 10px 0;
    padding: 5px;
    transition: 150ms;
    background-color: white;

    .payment-option-header {
      padding: 17px 10px;

      .tender-type-label {
        cursor: pointer !important;
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #202124;

        input {
          margin-right: 10px;
        }

        p {
          margin-bottom: 0;
          margin-left: 20px;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: #494949;
          margin-top: 7px;
        }
      }

      .payment-option-child {
        display: none;
        padding: 10px;
        transition: 150ms;

        &.active {
          display: block;
        }
      }
    }

    .payment-form-credit-card-container {
      .material-input > input {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #202124;
      }
    }
  }
}

.tenderButton {
  width: 100%;
  height: 69px;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 0px 25px 5px 20px;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  color: $Brand-Black;

  .card-number-image {
    padding: 18px 0px;

    svg {
      width: 3em;
      height: 2.5em;
    }
  }

  span {
    padding: 25px 0px 0px 0px;
    text-align: left;
    left: 10px;

    &.payment-image {
      padding: 19px 0px;
      width: 80px;
    }

    &.right-arrow-icon {
      text-align: right;
      padding: 23px 0px;
      position: absolute;
      right: 40px;
    }
  }
}

.pr-5px {
  padding-right: 5px;
}

.mr-5px {
  margin-right: 5px;
}

.wd-max-content {
  width: max-content;
}

.m-auto {
  margin: auto;
}
.mt-90px {
  margin-top: 5.5rem;
}

.mt-40px {
  margin-top: 2.5rem;
}

.mt-25px {
  margin-top: 1.5rem;
}

.mt-20px {
  margin-top: 1.25rem;
}

.mt-10px {
  margin-top: 0.625rem;
}
.mt-5px {
  margin-top: 0.312rem;
}
.mb-5px {
  margin-bottom: 0.312rem;
}

.pl-0px {
  padding-left: 0px !important;
}

.pl-10px {
  padding-left: 0.625rem;
}

.pl-25px {
  padding-left: 1.625rem;
}

.pr-10px {
  padding-right: 0.625rem;
}

.pr-25px {
  padding-right: 1.625rem;
}

.px-10px {
  padding: 0 0.625rem;
}

.p-10px {
  padding: 0.625rem;
}

.px-25px {
  padding: 0 1.625rem;
}

.px-rt-5px {
  padding-right: 5px !important;
}

.py-25px {
  padding: 1.625rem 0;
}

.p-25px {
  padding: 1.625rem;
}

.ml-25px {
  margin-left: 25px;
}

.mb-20px {
  margin-bottom: 20px;
}

.pl-0px,
.pr-0px {
  padding-left: 0;
  padding-right: 0;
}

.mb-30px {
  margin-bottom: 30px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-60px {
  margin-bottom: 60px !important;
}

.mb-40px {
  margin-bottom: 40px;
}

.ml-10px {
  margin-left: 10px;
}
.braintreePaymentContent {
  margin-top: 40px;

  .braintree-show-options [data-braintree-id='choose-a-way-to-pay'] {
    transform: none;
    display: none;
  }
  .braintree-placeholder {
    height: 0px;
  }
  .braintree-methods-initial {
    margin-top: 25px;
  }

  .braintree-large-button {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;
    background: 0;
  }
}

#payment-place-order-cont {
  width: 100%;
  position: relative;
  .payment-place-order-cont-label {
    width: 100%;
    position: absolute;
  }
  .payment-place-order-label {
    height: 2.75rem;
    padding: 0 2.5rem;
    border: 0;
    border-radius: 1.4rem;
    font-size: 0.9rem;
    line-height: 2.75rem;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    transition-property: background-color, color;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.15, 0.45, 0.6, 1);
    background-color: #e4002b;
    color: #fff;
    cursor: 'pointer';
    width: 100%;
    &:hover {
      background-color: #c80226;
    }
  }
    .paymenPlaceOrderDisable {
      color: #626365 !important;
      background-color: #D2D3D3 !important;
      &:hover {
        background-color: #D2D3D3 !important;
      }
    }
  #payment-provider-place-order-form {
    position: absolute;
    margin-left: 10px;

    // .gpay-button.black.short,
    // .gpay-button.black.plain {
    //   // background-image: none !important;
    // }
    .sandbox-warning {
      display: none !important;
    }
    .gpay-button,
    #secure-payment-field.submit {
      // border: none;
      // color: #ffffff00;
      // background: #ffffff09;
      // background-color: #ffffff09;
      // height: 2.75rem;
      // width: 100%;
      // cursor: pointer;

      &:hover {
        border: none;
      }
    }
  }
}
